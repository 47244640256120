<template>
  <div
    class="container size-mobile bg-element-white p-0 min-height-second paket"
    style="font-family: Montserrat"
  >
  
    <v-dialog/>
    <alert-error ref="ae"/>
    <date-picker v-model="startDate" valueType="format"></date-picker> 
    <div class="container mt-4" v-if="isLoading">
      <content-placeholders :rounded="true">
        <content-placeholders-text :lines="1" />
        <content-placeholders-img />
        <content-placeholders-text :lines="2" />
        <content-placeholders-text :lines="1" />
      </content-placeholders>
    </div>
    <div v-else>
      <div v-if="filteredData.length > 0" class="table-responsive" style="font-size: 12px;font-family:roboto">
        <table v-if="datas" class="table table-borderless table-striped table-hover mt-3">
          <thead>
            <tr>
              <th scope="col">Lokasi</th>
              <th scope="col">Nama</th>
              <th scope="col">Waktu</th>
              <th scope="col">Bukti</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="data in filteredData"
              :key="data.id" 
              @click="showModal(data)"
              >
              <td>{{data.lokasi}}</td>
              <td>{{data.nama}}</td>
              <td>{{data.v_created_at_waktu}}</td>
              <td>{{data.jml_gambar}} Foto</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="container" v-else>
        <div class="text-date d-flex bg-highlight justify-content-center bg-light rounded p-3"> Tidak ada data kejadian ditanggal ini</div>
      </div>
      
     
      <div class="d-flex">
         <router-link class="float" to="/ketidak-sesuaian/add">
          <i class="fa fa-plus my-float"></i>
        </router-link>
        <span class="float" @click="load()" style="background-color:#e0e0e0;color:#000;right:90px !important">
          <i class="fa fa-refresh my-float"></i>
        </span>
      </div>
      <!-- Modal  -->
      <modal v-if="isModalVisible" @close="closeModal" style="z-index: 99">
        <!-- Override Modal Header -->
        <template v-slot:header><b style="color: #2b80ac">Update Data Cek Lokasi</b></template>
        <!-- Override Modal body -->
        <template v-slot:body>
          <form id="edit-form" class="form-horizontal" method="POST" @submit.prevent="update(dataModal.id)" enctype="multipart/form-data"> 
           <fieldset>
              <legend class="legend-input">Nama Kejadian</legend>
              <input type="text" name="nama_tempat" Placeholder="contoh: Pintu Jebol" class="form-control" autocomplete="off" v-model="dataModal.nama" required/>
            </fieldset>
            <fieldset>
              <legend class="legend-input">Lokasi</legend>
              <textarea name="tujuan" class="form-control" placeholder="contoh: Disebelah gedung A" autocomplete="off" v-model="dataModal.lokasi" style="resize: none;"></textarea>
            </fieldset>
            <fieldset>
              <legend class="legend-input">Keterangan</legend>
              <textarea name="tujuan" class="form-control" autocomplete="off" v-model="dataModal.keterangan" style="resize: none;"></textarea>
            </fieldset>
            <div class="mb-1">
              <legend class="legend-input mt-3">Status Dilaporkan</legend>
              <div class="d-flex mt-2">
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="menginap" value="1" v-model="dataModal.status_dilaporkan"/>
                  <label class="form-check-label" for="status">Sudah</label>
                </div>
                <div class="form-check ml-4">
                  <input class="form-check-input" type="radio" name="menginap" value="0" v-model="dataModal.status_dilaporkan"/>
                  <label class="form-check-label" for="exampleRadios2">Belum</label>
                </div>
              </div>
            </div>
            <fieldset>
              <legend class="legend-input">Tgl Dilaporkan</legend>
              <input type="date" name="nama_tempat" class="form-control" autocomplete="off" v-model="dataModal.v_tgl_dilaporkan" required/>
            </fieldset>
            <fieldset>
              <legend class="legend-input">Nama Penerima Laporan</legend>
              <input type="text" name="nama_tempat" class="form-control" autocomplete="off" v-model="dataModal.nama_penerima_laporan" required/>
            </fieldset> 
            
            <!-- Foto -->
            <div class="text-4 mt-3 mb-3">Bukti Foto</div>
            <div>
              <content-placeholders :rounded="true" v-if="isLoadingImg">
                <content-placeholders-img />
              </content-placeholders>
              <div
                  v-else
                  is="todo-item"
                  v-for="(todo, index) in todos"
                  v-bind:key="todo.id"
                  v-bind:img="(todo.file_url ? todo.file_url: require('../../assets/img/upload.png'))"
                  v-bind:close="require('../../assets/img/clear-icon.png')" 
                  v-on:remove="deleteImg(todo.id,index)"
                  v-on:open="modalShowImg(index,todo.file_url ? 'update' : 'new')" >
              </div> 

              <!-- Button append -->
              <div class="d-flex align-items-center mb-3" @click="addNewImage" style="cursor:pointer">
                <img src="../../assets/img/plus-image.png" style="width:25px;"/>
                <span class="pl-2 add-foto">Tambah Foto</span>
              </div> 
            </div>
            
            <button class="btn-blue mt-4" style="width:100%" :class="{'disable': isLoadingUpload}"  type="submit">
              <span class="d-flex align-items-center justify-content-center" v-if="isLoadingUpload">
                  <div class="spinner-border text-primary mr-3 spinner-border-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                Sedang Upload...
              </span>
              <span v-else>Simpan</span>
            </button> 
            <span class="btn btn-danger mt-3" style="width:100%" @click="modalDelete(dataModal.id)" :class="{'disable': isSubmit}" type="submit">Hapus</span> 
          </form>
        </template>
      </modal>
      <!-- Modal Img -->
      <modal v-show="isModalVisibleImg" @close="closeModalImg">
        <!-- Override Modal Header -->
        <template v-slot:header>Unggah Foto</template>
        
        <!-- Override Modal body -->
        <template v-slot:body>
          <form class="uploadFile d-flex flex-column justify-content-center"  @submit.prevent="uploadSubmit()" enctype="multipart/form-data" method="post" style="position:absolute;bottom:0;width:95%;height:100%" >
              <div id="preview" class="d-flex align-items-center justify-content-center mb-3" style="height:80%;">
                <img v-if="imgUploaded" :src="imgUploaded" style="object-fit: contain;width: 100%;height: 100%;text-align:center"/>
              </div>
              <a class="btn btn-daftar" href="" style="width: auto !important;">
                  <span class="" style="float:left;margin-right:-15px;margin-top:-3px"><img src="../../assets/img/cam.png" alt="Attach" style="width:25px;"></span>
                  Ambil Gambar
                  <input id="file" name="file" type='file' class="attach" accept="image/*" capture  @change="onFileChange"/>
              </a>
              <button v-if="imgUploaded" :disabled="isLoadingUpload" id="submit" class="btn btn-login mt-2" style="width: auto !important;">
                <span class="d-flex align-items-center justify-content-center" v-if="isLoadingUpload">
                    <div class="spinner-border text-primary mr-3 spinner-border-sm" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  Sedang Upload...
                </span>
                <span v-else>Upload</span>
                
              </button>
          </form>
        </template>
      </modal>
      <alert ref="c"/>
    </div>
  </div>
</template>
 
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";   

// import $ from "jquery";
// import _ from "lodash"; 
import Api from "../../Api";
import Vue from 'vue';
import alertError from "../../components/AlertError.vue";
import alert from "../../components/Alert.vue";

Vue.component('todo-item', {
  template: '\
    <div class="d-flex bd-highlight flex-column">\
      <img v-bind:src="img" class="foto-absen mb-3" v-on:click="$emit(\'open\')"/>\
      <img v-bind:src="close" v-on:click="$emit(\'remove\')" class="close-btn"/>\
    </div>\
  ',
  props: ['img','close','isStore']
})
export default {
  name: "KetidakSesuaian",
  components: {
    DatePicker,
    modal: () => import("../../components/Modal.vue"),
    alert,
    alertError, 
  },
  data() {
    var currentdate = new Date(); 
    var datetime = currentdate.getFullYear() + "-"
            + ('0' + (currentdate.getMonth()+1)).slice(-2)  + "-" 
            + ('0' + (currentdate.getDate())).slice(-2)
    return {
      todos: [
        {
          id: 1,
          img: require('../../assets/img/upload.png'),
        },
      ],
      
      nextImageId: 2, 
      startDate:datetime,
      isLoading: true,
      datas: [],
      isLoadingUpload:false,
      total: 0,
      dataModal: [],
      dataImg: [],
      isModalVisible: false,
      isModalVisibleImg: false,
      isLoadingImg: false,
      updatedContent: "",
      token: '',
      myData: '',
      indexImg: '',
      file_upload:'',
      attachment_id:'',
      imgUploaded:'',
      isSubmit: false,
      img: require('../../assets/img/upload.png'),
      dataFiltered: 0,
      typeSubmit: ''
    };
  },

  async mounted() {
    this.token = JSON.parse(localStorage.getItem('token'))
    this.myData = JSON.parse(localStorage.getItem('cred'))
    this.load();
    // console.log(JSON.parse(localStorage.getItem('cred')));
  },

  methods: {
    async load() {
      this.isLoading = true,
      // Get Data Lokasi
      await Api.get("satpam/ketidaksesuaian/"+this.myData.id_tempat_kerja,{
        headers: {Authorization: 'Bearer ' + this.token}
      })
      .then((response) => {
          // console.log(response)
          this.datas = response.data.data;
          // console.log(this.datas)
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.$refs.ae.showAlert()
        });
    },
    // Append foto baru
    addNewImage: function () {
      this.todos.push({
        id: this.nextImageId++,
        img: this.img
      })
      this.newTodoText = '' 
    }, 
    // Update data lokasi
    async update(id){
      this.isLoadingUpload = true
      const data = {
        user_id: this.myData.id,
        workplace_id: this.myData.id_tempat_kerja,
        nama: this.dataModal.nama,
        lokasi: this.dataModal.lokasi,
        keterangan: this.dataModal.keterangan,
        status_dilaporkan: this.dataModal.status_dilaporkan,
        tgl_dilaporkan: this.dataModal.v_tgl_dilaporkan,
        nama_penerima_laporan: this.dataModal.nama_penerima_laporan,
      }

      await Api.put("satpam/ketidaksesuaian/"+id,data,{
        headers: {Authorization: 'Bearer ' + this.token}
      })
      .then((response) => {
        console.log(response)
        this.closeModal()
        this.load()
        this.$refs.c.showAlert('Sukses','Berhasil Update Data Lokasi')
      })
      .catch((error) => {

        console.log(error);
        this.$refs.ae.showAlert()
      });
      this.isLoadingUpload = false 
    }, 
    // Show modal data per lokasi
    showModal(data) {
      this.loadImg(data.id)
      // console.log(data)
      this.dataModal = data; 
      // this.updatedContent = data.pengirim
      this.isModalVisible = true;
    },
    // Modal show img per id
    modalShowImg(id,type) {
      this.typeSubmit = type
      this.imgUploaded = this.todos[id].file_url
      this.attachment_id = this.todos[id].id
      this.indexImg = id
      // this.idImg = id;
      this.isModalVisibleImg = true 
    },
    // close modal update
    closeModal() {
      this.load()
      this.isModalVisible = false;
    },
    // close modal img
    closeModalImg() {
      this.isModalVisibleImg = false;
    },
    // delete data lokasi
    modalDelete(id){
      console.log(id)
      this.$modal.show('dialog', {
        title: 'Yakin Hapus Data lokasi ?',
        text: 'Data yang telah dihapus tidak dapat dikembalikan',
        buttons: [
          {
            title: 'Tutup',
            handler: () => {
              this.$modal.hide('dialog')
              // this.$router.push(this.$route.query.redirect || '/')
            }
          }, 
          {
            title: 'Hapus',
            handler: () => {
              Api.delete("satpam/ketidaksesuaian/"+id,{
                headers: {Authorization: 'Bearer ' + this.token}
              })
              .then(() => {
                this.closeModal()
                this.$modal.hide('dialog')
                this.load()
              })
              .catch((error) => {

                console.log(error);
                this.$refs.ae.showAlert()
              });
              // this.$router.push(this.$route.query.redirect || '/')
            }
          }, 
        ]
      })
    },
    // Looping img dalam modal
    async loadImg(id) {
      this.isLoadingImg = true
      // Get Data Lokasi
      await Api.get("satpam/ketidaksesuaian/detail/"+id,{
        headers: {Authorization: 'Bearer ' + this.token}
      })
      .then((response) => {
        // this.dataImg = response.data.data
        this.todos = response.data.data
        this.isLoadingImg = false
          // console.log(this.datas)
        })
        .catch((error) => {
          console.log(error);
          this.$refs.ae.showAlert()
        });
    },
    // onchange ketika gambar dipilih
    onFileChange(e) {
      const file = e.target.files[0]; 
      this.imgUploaded = URL.createObjectURL(file);
      this.file_upload = e.target.files[0] 
    },
    //MENGIRIM FILE UNTUK DI-UPLOAD
    async uploadSubmit() {
        
        this.isLoadingUpload = true

        // Update
        if(this.typeSubmit == 'update'){
          let formData = new FormData();
          formData.append('file_upload', this.file_upload)
          formData.append('attachment_id', this.attachment_id)
          
          await Api.post('satpam/ketidaksesuaian/upload-foto-update', formData, {
              headers: {
                Authorization: 'Bearer ' + this.token, 
                'Content-Type': 'multipart/form-data'
                
              }
          })
          .then((response) => {
              this.todos[this.indexImg].file_url = response.data.data.file_url
              console.log(response)
              // Jika berhasil upload gambar
              this.closeModalImg()
              this.$refs.c.showAlert('Sukses','Foto berhasil diupload')
              this.isLoadingUpload = false
          })  
        // New
        }else{
          let formData = new FormData();
          formData.append('file_upload', this.file_upload)
          formData.append('satpam_ketidaksesuaian_id', this.dataModal.id)
          formData.append('jenis_upload', 'satpam_ketidaksesuaian')
          
          await Api.post('satpam/ketidaksesuaian/upload-foto-new', formData, {
              headers: {
                Authorization: 'Bearer ' + this.token, 
                'Content-Type': 'multipart/form-data'
                
              }
          })
          .then(() => {
              this.loadImg(this.dataModal.id)
              this.closeModalImg()
              
              // Jika berhasil upload gambar
              // this.$refs.c.showAlert('Sukses','Foto berhasil diupload')
              this.isLoadingUpload = false
          })
        }
        
    },
    // Hapus Foto
    async deleteImg(id,index){
      const data = {
        attachment_id: id,
      }
      await Api.post("satpam/ketidaksesuaian/delete-foto",data,{
        headers: {
          Authorization: 'Bearer ' + this.token,
          }
      })
      .then((response) => {
        console.log(response)
        this.todos.splice(index, 1)
      })
      .catch((error) => {

        console.log(error);
        this.$refs.ae.showAlert()
      });
    }
  },
  // Untuk Search
  computed: {
    filteredData() {
      var vm = this;
      var startDate = vm.startDate;
      // console.log(this.datas)
      return this.datas.filter(item => {
        var date = item.v_created_at_tanggal;
        if (date == startDate) {
          // console.log(item.length)
          return date == startDate;
        }
      });
    }
  }
};
</script> 
<style>
.close-btn{
  width: 20px;
  position: absolute;
  right: 26px;
  margin-top: 10px;
  cursor:pointer;
}
  /* th,td{
    padding-left:3px;
    padding-right:3px;
  } */
</style>